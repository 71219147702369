import { PageHeader, Socials } from "../Globals";

const pageTitle = "Author";
const pageDescription = "Learn more about Alvin & Louis' background and expertise";
// const pageDescription = "Learn more about Alvin's background and expertise";

function LRAuthor() {
  return (
    <div id="louis" className="row d-flex justify-content-center">
      <div className="col-8 col-md-4 me-md-5">
        <div className="d-flex justify-content-center">
          <img src="/img/rosenbergl.png" alt="Louis Rosenberg" className="col-10"/>
        </div>
        <h2 className="text-center pt-2">Louis Rosenberg</h2>
        {/* social */}
        <div className="container row col-12 text-center d-flex justify-content-center align-items-end">
          <a href="https://www.linkedin.com/in/louis-rosenberg/" className="p-2 me-4 col-auto">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="linkedin">
              <title>LinkedIn</title>
              <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
            </svg>
            &emsp;louis-rosenberg
          </a>
        </div>
      </div>
      <div className="col-11 col-lg-6 my-auto mx-1">
        {/* <div className="p-3 p-lg-5 bg-gray"> */}
          <p>Louis Rosenberg, PhD is an early pioneer of virtual and augmented reality and a longtime AI researcher. His work began over thirty years ago in VR labs at Stanford, NASA, and Air Force Research Laboratory where he developed the Virtual Fixtures platform, the first system enabling users to interact with a mixed reality of real and virtual objects. In 1993, he founded the early VR company Immersion Corporation and brought it public on Nasdaq. In 2004 he founded Outland Research, an early developer of augmented reality and spatial media technology acquired by Google. In 2014 he founded Unanimous AI, a company focused on amplifying the intelligence of networked human groups in pursuit of Collective Superintelligence. He received his PhD from Stanford University, was a tenured professor at California State University, and has been awarded over 300 patents for VR, AR, and AI technologies. He is currently CEO of Unanimous AI, the Chief Scientist of the Responsible Metaverse Alliance and writes often for major publications about the dangers of emerging technologies. His previous books include Arrival Mind, UPGRADE, and Monkey Room.</p>
        {/* </div> */}
      </div>
    </div>
  );
}

export default function Author() {
  return (
    <main>
      <div className="container pt-4">
        <div id="alvin" className="row d-flex justify-content-center">
          <PageHeader title={pageTitle} description={pageDescription}/>
          <div className="col-8 col-md-4 me-md-5">
            <img src="/img/profile.jpg" alt="Alvin Graylin"/>
            {/* temp */}<h2 className="text-center pt-2">Alvin Wang Graylin</h2>
            <Socials hide="d-none" />
          </div>
          <div className="col-11 col-lg-6 my-auto mx-1">
            {/* <div className="p-3 p-lg-5 bg-gray"> */}
              <p>Alvin Wang Graylin (汪丛青) is a recognized technology pioneer, entrepreneur, executive, and thought leader with 30+ years of experience delivering innovative products in the AI, XR, cybersecurity and semiconductor industries. He is the co-author of <cite>Our Next Reality: How the AI-powered Metaverse will Reshape the World </cite>, published by Hachette Book Group. Having built his foundation in VR research at the Human Interface Technology Lab over 30 years ago, Alvin has also developed his expertise in AI and Natural Language Processing after extensive studies at the University of Washington and MIT.</p>
              <p>In his recent professional journey, Alvin served as the China President of HTC (2016-2023) and continues to contribute as Global VP of Corporate Development at HTC, a global leader in the VR/AR space. His commitment to the immersive computing field is further reflected in his roles as Vice-Chairman of the Industry of Virtual Reality Alliance (IVRA), President of the Virtual Reality Venture Capital Alliance (VRVCA), and board-member of the Virtual World Society (VWS).</p>
              <p>Alvin's entrepreneurial spirit is evidenced by his founding of four venture-backed startups in areas ranging from AI-based natural language search (2005), to professional mobile social (2010), AR-based local search (2012), and big-data AI analytics (2000) across China and the US. His broad operational experience also includes global P&L roles at multinational corporations like Intel, Trend Micro, WatchGuard Technologies, and IBM.</p>
              <p>Alvin holds an M.S. in Computer Science from MIT, where he specialized in AI, and an M.S. in Business from MIT's Sloan School of Management on the entrepreneurship and operations track. He also earned a B.S. in Electrical Engineering from the University of Washington, focused on VR, AI, and CPU architecture. He was born in China during the Cultural Revolution to Eurasian parents and emigrated to the U.S. in 1980 as the country's reform period started. Alvin has recently relocated back to the U.S. after almost 20 years working/living in China during its internet and mobile boom and is currently based in Seattle, Washington.</p> 
            {/* </div> */}
          </div>
        </div>
        <LRAuthor />
      </div>
    </main>
  );
}
